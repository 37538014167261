body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.fixed-loading-page {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background-color: #FF9800;
    z-index: 11111;
    color: white;
    font-size: 47px;
    padding-top: 100px;
    text-align: center;
}

.page {
    padding-top: 63px;
}

.page .page-content {
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 30px;
}


ul.bread-crumb {
    list-style: none;
    background-color: #636667;
    padding: 5px 10px;
    padding-top: 2px;
    color: white;
    margin: 0px;
}

ul.bread-crumb li.bread-crumb-list-item {
    display: inline;
    padding: 0px;
    line-height: 10px;
}

ul.bread-crumb li.bread-crumb-list-item a {
    text-decoration: none;
    color: white;
    font-size: 13px;
    line-height: 10px;
    padding-left: 10px;
    padding-right: 10px;
}

ul.bread-crumb li.bread-crumb-list-item a:hover {
    text-decoration: underline;
}

.election-grid {
    border-right: 1px solid #ccc;
}

.tally-sheet-vote-type {
    padding-bottom: 0 !important;
}

.tally-sheet-code-list {
    margin-top: 0;
    padding-left: 5px;
}

.tally-sheet-grid div {
    display: block;
}

.tally-sheet-code-list li {
    list-style: none;
    font-weight: bold;
    height: 35px;
    padding-left: 10px;
    padding-top: 5px;
}

.tally-sheet-code-list li:hover {
    background-color: #cccc;
}

.btn-select {
    background: green;
    border: 1px solid green;
}

.btn-list {
    background: #bbb;
}

.tally-sheet-code-list li:hover > .btn-list {
    background: #4778cf;
}

.tally-sheet-code-list li a {
    text-decoration: none;
    margin-left: 10px !important;
    font-weight: normal;
    color: #fff;
    padding: 5px;
    border-radius: 5px;
    float: right;
    margin-right: 10px;
    width: 50px;
    height: 20px;
    text-align: center;
    cursor: pointer;
}

.tally-sheet-code-list .tally-sheet-code-list-item {

}

.tally-sheet-code-grid {
    padding-top: 0 !important;
}

.tally-sheet-page-header {
    display: flex;
    padding-bottom: 5px;
    padding-top: 5px;
    padding-left: 5px;
    padding-right: 5px;
    margin-bottom: 11px;
    background-color: #edf2f9;
}

.tally-sheet-page-header-election {
    flex: 1;
    margin: 0px;
    line-height: 30px;
}

.tally-sheet-page-header-area {
    margin: 0px;
    line-height: 30px;
    margin-right: 15px;
    color: #636667;
}

.tally-sheet-page-header-tally-sheet-code {
    margin-right: 10px;
    line-height: 30px;
    color: #9E9E9E;
    font-size: 25px;
}

.tally-sheet-page-header-info-link.MuiIconButton-root {
    padding: 0px 10px;
    color: #607D8B;
}

.report-view-status .report-view-status-text {
    background-color: #eeeeee;
    padding: 10px;
    border-radius: 5px;
    color: #5178c8;
    margin-top: 10px;
    margin-bottom: 10px;
}

.report-view-status .report-view-status-actions {
    text-align: right;
}

.tally-sheet-list-row-actions .MuiButton-contained,
.tally-sheet-list-row-actions .MuiButton-outlined {
    margin-right: 10px;
    font-size: 11px;
    padding: 1px 7px;
    min-width: 0px;
    margin-bottom: 3px;
}

.report-view-status-actions .MuiButton-contained,
.report-view-status-actions .MuiButton-outlined {
    margin-right: 10px;
    min-width: 0px;
    margin-bottom: 3px;
}

.page-bottom-fixed-action-bar .MuiButton-contained,
.page-bottom-fixed-action-bar .MuiButton-outlined {
    margin-right: 10px;
}

.tabulation-linear-progress-bar {
    padding-top: 30px;
    padding-bottom: 30px;
}

.tabulation-linear-progress-bar .tabulation-linear-progress-bar-label {
    padding-bottom: 5px;
    color: #607D8B;
    font-size: 13px;
}

.election-list {
    text-align: center;
    padding-top: 20px;
}

.election-list a.election-list-item {
    padding: 10px;
    background-color: #e7e7e7;
    margin: 10px;
    display: inline-block;
    width: 260px;
    text-align: center;
    color: #5178c8;
    text-decoration: none;
    border-radius: 7px;
}

.election-list-item-parent, .election-list-item-children {
    display: block;
}

.election-list-item-children a.election-list-item {
    margin-top: 10px;
}

.sub-elections {
    border: 1px solid #ccc;
    text-align: center;
}

.root-election {
    background: lightgrey;
}

.root-election a.election-list-item {
    background-color: #5178c8;
    color: #e7e7e7 !important;
}

.show-subElections {
    width: 100%;
    text-align: right;
    padding-top: 8px;
}

.election-panel {
    border: 1px solid #ccc;
    border-radius: 4px;
}

#wraphalf {
    width: 600px;
    height: 390px;
    padding: 0;
    overflow: hidden;
}

#framehalf {
    position: absolute;
    float: right;
    -ms-zoom: 0.5;
    -moz-transform: scale(0.50);
    -moz-transform-origin: 0 0;
    -o-transform: scale(0.50);
    -o-transform-origin: 0 0;
    -webkit-transform: scale(0.50);
    -webkit-transform-origin: 0 0;
}

#realease-header {
    display: table;
    width: 100%;
}

#realease-header-content {
    display: table-row;
}

#realease-header-content > div {
    display: table-cell;
    text-align: center;
}

#upload-progress {
    margin-bottom: -7px;
    padding-left: 6px;
}

.tabulation-page-message {
    padding: 60px 20px 100px 20px;
    text-align: left;
    color: rgb(99, 102, 103);
    display: flex;
}

.tabulation-page-message .tabulation-page-message-content {
    flex: 1;
}

.activity-wrapper {
    border-bottom: 1px solid #ccc;
}

.activity-list {
    margin-bottom: 0;
}

.activity-row {
    list-style: none;
    border-left: 1px solid #ccc;
    padding-bottom: 25px;
}

.activity-block {
    display: inline-table;
    vertical-align: top;
}

.activity-icon {
    background: #ccc;
    border-radius: 50%;
    color: #444d56;
    margin-left: -15px;
    padding: 2px;
}

.activity-details {
    margin-left: 10px;
}

.activity-row li {
    list-style: none;
}

.activity-proof, .activity-proof-link {
    font-size: 14px;
}

.activity-proof-link, .activity-tallysheet-link {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
}

.activity-tallysheet-link .MuiButton-label {
    font-size: 16px;
}

.MuiTableCell-head {
    font-weight: 600 !important;
}
